import { computed } from "vue";
import { useDefaultAbTest } from "~/composables/useAbTest";

export function useAB() {
  const { handleUserHasAbTestGroup } = useDefaultAbTest();

  const loadingScreenAppVariantActive = computed<boolean>(() => {
    return handleUserHasAbTestGroup("loading_screen_app", "loading_screen_app_variant");
  });

  const removeSepaVariantActive = computed<boolean>(() => {
    return handleUserHasAbTestGroup("remove_sepa", "remove_sepa_variant");
  });

  return {
    loadingScreenAppVariantActive,
    removeSepaVariantActive,
  };
}
